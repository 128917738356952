h1, h2, h3, h4, h5 {
  margin-top: 24px;
  margin-bottom: 24px;
}
h1 {
  font-weight: bold;
  font-size: 1.4rem;
}
p {
  font-size: 0.8rem;
}
h2 {
  font-weight: bold;
  font-size: 1.1rem;
}
h4 {
  font-weight: bold;
  font-size: 1.2rem;
}
table {
  margin-top: 20px;
  width: 100%;
}
table > table {
  margin-top: 0px;
}
.print {
  padding: 40px;
}
th,
td,
tr {
  text-align: left !important;
  border-bottom: 1px solid #eee !important;
}
.header {
  text-align: center;
}
.nopadding {
  margin: 0px !important;
}
